import "./footer.css";
const Footer = () => {
  return (
    <footer className="footer">
      SergenHoca &nbsp; <a href="/"> Anasayfa</a> &#169; 2024 | Tüm Hakları
      Saklıdır.
    </footer>
  );
};

export default Footer;
